import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  Button,
  Tooltip,
  Box,
} from '@mui/material';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import { styled } from '@mui/material/styles';
import storeConnector from '@src/store/storeConnector';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import { ServiceState } from '@src/store/reducers/serviceData';
import { SiteMeta } from '@src/types/SiteMeta';
import tscSx from './TSCConfigSx';

interface TSCPointCellProps {
  label: string;
  pointValue: string | number | null | undefined;
  payload: string;
  siteMeta: SiteMeta;
  isControlsLocked: ServiceState['isControlsLocked'];
}

const StyledPointTableCell = styled(TableCell)({
  borderBottom: 0,
});

function TSCPointCell({
  label,
  pointValue,
  payload,
  isControlsLocked,
  siteMeta,
}: TSCPointCellProps) {
  const [newValue, setNewValue] = useState<string>('');
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  const { sendCommand } = useCommandsModalContext();
  const handleCommand = () => {
    sendCommand('WriteToVariant', {
      P: payload.replace('[input value]', newValue),
    });
    setNewValue('');
  };

  return (
    <TableRow>
      <StyledPointTableCell className='modes-cell'>
        {label}
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell'>
        {pointValue}
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell'>
        <TextField
          value={newValue}
          id='newTscValue'
          inputProps={{
            sx: tscSx.configTable.textField,
          }}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
        />
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell' sx={tscSx.configSaveButton}>
        {isCommandsEnabled() && (
          <Tooltip
            title={isControlsLocked ? tooltipTitle : ''}
            placement='top'
            disableHoverListener={!isControlsLocked}
          >
            <Box>
              <Button
                variant='contained'
                disabled={isControlsLocked || newValue.length < 1}
                onClick={handleCommand}
              >
                Save
              </Button>
            </Box>
          </Tooltip>
        )}
      </StyledPointTableCell>
    </TableRow>
  );
}

export default storeConnector(TSCPointCell, {
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
