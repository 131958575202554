export enum UserRoles {
  SuperAdmin = 'SuperAdmin',
  Administrator = 'Administrator',
  Engineer = 'Engineer',
  Operator = 'Operator',
  Viewer = 'Viewer',
}

const adminRoleGroups = [UserRoles.SuperAdmin, UserRoles.Administrator];

const engineerRoleGroups = [...adminRoleGroups, UserRoles.Engineer];

const actionRoleGroups = [...engineerRoleGroups, UserRoles.Operator];

const roleGroups = [...actionRoleGroups, UserRoles.Viewer] as const;

export { roleGroups, adminRoleGroups, engineerRoleGroups, actionRoleGroups };

export type UserRole = (typeof roleGroups)[number];
