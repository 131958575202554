import {
  actionRoleGroups,
  adminRoleGroups,
  engineerRoleGroups,
  UserRole,
  UserRoles,
} from './roles';

const isSuperAdmin = (role: UserRole) => role === UserRoles.SuperAdmin;

const isAdmin = (role: UserRole) => adminRoleGroups.includes(role);

const engineerOrAdmin = (role: UserRole) => engineerRoleGroups.includes(role);

const isActionRole = (role: UserRole) => actionRoleGroups.includes(role);

const getUserRole = (roles: string[]): UserRole | '-No roles-' => {
  if (roles.includes('superadmin')) {
    return UserRoles.SuperAdmin;
  }
  if (roles.includes('administrator')) {
    return UserRoles.Administrator;
  }
  if (roles.includes('engineer')) {
    return UserRoles.Engineer;
  }
  if (roles.includes('operator')) {
    return UserRoles.Operator;
  }
  if (roles.includes('viewer')) {
    return UserRoles.Viewer;
  }
  return '-No roles-';
};

export { isSuperAdmin, isAdmin, engineerOrAdmin, isActionRole, getUserRole };
