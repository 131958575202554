import { Box, Tooltip } from '@mui/material';
import BreakerCommandsDialog from '@src/sld/components/Breaker/BreakerCommandsDialog';
import { GRAY, GREEN, RED } from '@src/sld/components/Shared';
import { ServiceState } from '@src/store/reducers/serviceData';
import storeConnector from '@src/store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import isCommandsEnabled from '@src/utils/isCommandsEnabled';
import React, { ReactElement, useState } from 'react';

interface BreakerProps {
  label: string;
  status: string | number | null | undefined;
  enableBreakerCommands?: boolean;
  yCoordinate: number;
  xCoordinate: number;
  siteMeta: SiteMeta;
  isControlsLocked: ServiceState['isControlsLocked'];
}

function Breaker({
  status,
  label,
  xCoordinate,
  yCoordinate,
  isControlsLocked,
  siteMeta,
  enableBreakerCommands,
}: BreakerProps): ReactElement {
  const [showBreakerCmdDialog, setShowBreakerCmdDialog] = useState(false);
  const commandAccessEnabled = isCommandsEnabled() && !isControlsLocked;
  const { controlsLockConfig } = siteMeta.ui;
  const tooltipTitle = `Controls disabled. ${controlsLockConfig?.pointName} is above the threshold of ${controlsLockConfig?.thresholdMax}.`;

  const setFillColor = (v: string | number | null | undefined) => {
    if (v === null || v === undefined) return GRAY;
    return v !== 0 ? RED : GREEN;
  };

  const breakerTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'middle',
    transform: '',
    x: xCoordinate,
    y: yCoordinate,
  };

  const breakerProps = {
    ...(enableBreakerCommands && commandAccessEnabled
      ? {
          style: { cursor: 'hand' },
          onClick: () => setShowBreakerCmdDialog(true),
        }
      : {}),
  };

  return (
    <>
      <Tooltip
        title={isControlsLocked ? tooltipTitle : ''}
        placement='top'
        disableHoverListener={!isControlsLocked}
      >
        <Box>
          <g {...breakerProps}>
            <text {...breakerTextProps}>{label}</text>
            <rect
              y='765'
              width='27'
              height='27'
              className='st5'
              fill={setFillColor(status)}
            />
          </g>
        </Box>
      </Tooltip>

      {showBreakerCmdDialog && (
        <BreakerCommandsDialog
          breakerName={label}
          setShowBreakerCmdDialog={setShowBreakerCmdDialog}
        />
      )}
    </>
  );
}

export default storeConnector(Breaker, {
  config: ['siteMeta'],
  service: ['isControlsLocked'],
});
