import { UserRole, UserRoles } from '@src/services/auth/roles';
import Keycloak from 'keycloak-js';
import { Action } from 'redux';

export interface UserState {
  role: UserRole;
  username: string;
  email: string;
  name: string;
  keycloak: Keycloak | null;
  ip: string;
}

const initState: UserState = {
  role: UserRoles.Viewer,
  username: '',
  email: '',
  name: '',
  keycloak: null,
  ip: '',
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: UserState = initState,
  action: Action<string> & UserState,
) => {
  const handlers: Record<string, () => UserState> = {
    LOGOUT: () => ({ ...state, ...initState }),
    UPDATE_USER_MODULE: () => {
      const { type: _type, ...cleanAction } = action;
      return { ...state, ...cleanAction };
    },
  };
  if (action.type && handlers[action.type] !== undefined) {
    return handlers[action.type]();
  }
  return state;
};
