import React from 'react';
import { dispatcher, store } from '@store';
import { notifyError } from '@store/actionCreators/notifications';
import { ALERTS_SERVICE_URL } from '@utils';
import { useTranslation } from 'react-i18next';
import Popup from '@components/_elements/Popup/Popup';
import Button from '@components/_elements/Button/Button';

const ClearAlertsPopup = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const sendClearAlerts = async () => {
    await fetch(`${ALERTS_SERVICE_URL}/alerts/clear-all`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${store.getState().user.keycloak?.token}`,
      },
    })
      .then(() => {
        store.dispatch(
          dispatcher('UPDATE_ACTIVE_ALERTS', {
            setAlerts: [],
          }),
        );
      })
      .catch(() => notifyError('Failed to clear alerts.'));
    close();
  };

  return (
    <Popup
      isOpen={isOpen}
      title={t('CONFIRM_EXECUTION_QUESTION')}
      close={() => close()}
      bodyClass='content-popup'
    >
      <div style={{ margin: '20px' }}>{t('CLEAR_ALERTS')}</div>
      <div className='btn-row'>
        <Button onClick={() => sendClearAlerts()} size='m'>
          {t('OK_UPPERCASE')}
        </Button>
        <Button onClick={() => close()} size='m'>
          {t('CANCEL_UPPERCASE')}
        </Button>
      </div>
    </Popup>
  );
};

export default ClearAlertsPopup;
