import React, { ReactElement } from 'react';
import Unit from '@src/sld/components/Unit/Unit';
import { UnitConfig } from '@src/sld/types';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';
import Switch from '@src/sld/components/Switch/Switch';
import { getUnitConfig } from '@src/sld/utils';

type UnitsProps = {
  unitConfigs: UnitConfig[];
};

// eslint-disable-next-line max-lines-per-function
function Switch1Units(props: UnitsProps): ReactElement {
  const { unitConfigs } = props;

  return (
    <g transform='translate(-50,-400)'>
      <Switch
        label='89F11-2'
        isClosed={false}
        translateXCoordinate={410}
        translateYCoordinate={715}
        stroke='green'
      />

      {/* top vertical line */}
      <VerticalLine
        length={30}
        color='white'
        width={0.5}
        translateXCoordinate={410}
        translateYCoordinate={725}
      />

      {/* top horizontal line */}
      <HorizontalLine
        length={400}
        color='white'
        width={0.5}
        translateXCoordinate={225}
        translateYCoordinate={756}
      />

      {/* units */}
      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={225}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[0])}
        rectangleXCoordinate='190'
        rectangleYCoordinate='782'
        textStartingXCoordinate={195}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={125}
        lineTransformYCoordinate={757}
        skipHorizontalLine
      />

      <VerticalLine
        length={75}
        color='white'
        width={0.5}
        translateXCoordinate={300}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[1])}
        rectangleXCoordinate='270'
        rectangleYCoordinate='782'
        textStartingXCoordinate={275}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={215}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={390}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[2])}
        rectangleXCoordinate='350'
        rectangleYCoordinate='782'
        textStartingXCoordinate={355}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={280}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={470}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[3])}
        rectangleXCoordinate='430'
        rectangleYCoordinate='782'
        textStartingXCoordinate={435}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={345}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={550}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[4])}
        rectangleXCoordinate='510'
        rectangleYCoordinate='782'
        textStartingXCoordinate={515}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={410}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={625}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[5])}
        rectangleXCoordinate='590'
        rectangleYCoordinate='782'
        textStartingXCoordinate={595}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={475}
        lineTransformYCoordinate={757}
      />
    </g>
  );
}

export default Switch1Units;
