const DataTypePointNameMap: Record<string, string> = {
  'Disable_Adaptive Vref_Fractal': 'integer',
  'Disable_Adaptive Vref_SCADA': 'integer',
  'Disable_Adaptive Vref': 'integer',
  'Disable_Adjustable Voltage Response_Fractal': 'integer',
  'Disable_Adjustable Voltage Response_SCADA': 'integer',
  'Disable_Adjustable Voltage Response': 'integer',
  'Disable_AGC Response_Fractal': 'integer',
  'Disable_AGC Response_SCADA': 'integer',
  'Disable_AGC Response': 'integer',
  'Disable_Configurable Frequency Response_Fractal': 'integer',
  'Disable_Configurable Frequency Response_SCADA': 'integer',
  'Disable_Configurable Frequency Response': 'integer',
  'Disable_Configurable Voltage Response_Fractal': 'integer',
  'Disable_Configurable Voltage Response_SCADA': 'integer',
  'Disable_Configurable Voltage Response': 'integer',
  'Disable_Dynamic P_Fractal': 'integer',
  'Disable_Dynamic P_SCADA': 'integer',
  'Disable_Dynamic P': 'integer',
  'Disable_Dynamic Q_Fractal': 'integer',
  'Disable_Dynamic Q_SCADA': 'integer',
  'Disable_Dynamic Q': 'integer',
  'Disable_Dynamic S Limit_Fractal': 'integer',
  'Disable_Dynamic S Limit_SCADA': 'integer',
  'Disable_Dynamic S Limit': 'integer',
  Disable_FFR_Fractal: 'integer',
  Disable_FFR_SCADA: 'integer',
  Disable_FFR: 'integer',
  Disable_FRRS_Fractal: 'integer',
  Disable_FRRS_SCADA: 'integer',
  Disable_FRRS: 'integer',
  'Disable_FFR Ext Trig_Fractal': 'integer',
  'Disable_FFR Ext Trig_SCADA': 'integer',
  'Disable_FFR Ext Trig': 'integer',
  'Disable_Frequency Response_Fractal': 'integer',
  'Disable_Frequency Response_SCADA': 'integer',
  'Disable_Frequency Response': 'integer',
  'Disable_P Correction_Fractal': 'integer',
  'Disable_P Correction_SCADA': 'integer',
  'Disable_P Correction': 'integer',
  'Disable_P Follow Ramped v2_Fractal': 'integer',
  'Disable_P Follow Ramped v2_SCADA': 'integer',
  'Disable_P Follow Ramped v2': 'integer',
  'Disable_P Follow Ramped_Fractal': 'integer',
  'Disable_P Follow Ramped_SCADA': 'integer',
  'Disable_P Follow Ramped': 'integer',
  'Disable_Power Factor_Fractal': 'integer',
  'Disable_Power Factor_SCADA': 'integer',
  'Disable_Power Factor': 'integer',
  'Disable_Power Smoothing_Fractal': 'integer',
  'Disable_Power Smoothing_SCADA': 'integer',
  'Disable_Power Smoothing': 'integer',
  'Disable_Power Threshold_Fractal': 'integer',
  'Disable_Power Threshold_SCADA': 'integer',
  'Disable_Power Threshold': 'integer',
  'Disable_Q Correction_Fractal': 'integer',
  'Disable_Q Correction_SCADA': 'integer',
  'Disable_Q Correction': 'integer',
  'Disable_Q Follow Ramped_Fractal': 'integer',
  'Disable_Q Follow Ramped_SCADA': 'integer',
  'Disable_Q Follow Ramped': 'integer',
  'Disable_Q Follow_Fractal': 'integer',
  'Disable_Q Follow_SCADA': 'integer',
  'Disable_Q Follow': 'integer',
  Disable_ramped_shutdown_Fractal: 'integer',
  Disable_ramped_shutdown_SCADA: 'integer',
  Disable_ramped_shutdown: 'integer',
  'Disable_SOC Bias_Fractal': 'integer',
  'Disable_SOC Bias_SCADA': 'integer',
  'Disable_SOC Bias': 'integer',
  'Disable_SOC Rebalance_Fractal': 'integer',
  'Disable_SOC Rebalance_SCADA': 'integer',
  'Disable_SOC Rebalance': 'integer',
  'Disable_Target SOC_Fractal': 'integer',
  'Disable_Target SOC_SCADA': 'integer',
  'Disable_Target SOC': 'integer',
  'Disable_Voltage Response 2 Step_Fractal': 'integer',
  'Disable_Voltage Response 2 Step_SCADA': 'integer',
  'Disable_Voltage Response 2 Step': 'integer',
  'Disable_Voltage Response_Fractal': 'integer',
  'Disable_Voltage Response_SCADA': 'integer',
  'Disable_Voltage Response': 'integer',
  ACBreaker: 'boolean_integer',
  Alarms: 'integer',
  AllowedMaxP: 'float',
  AllowedMaxQ: 'float',
  AllowedMinP: 'float',
  AllowedMinQ: 'float',
  AphA: 'float',
  AphB: 'float',
  AphC: 'float',
  Application: 'string',
  AvgCellT: 'float',
  AvgCellV: 'float',
  AvgHumidity: 'float',
  BESSkW: 'float',
  CellMaxT: 'float',
  CellMinT: 'float',
  ChaCurrLimit: 'float',
  ChaSt: 'string',
  ControlMode_Authority: 'integer',
  Current: 'float',
  CurrentFault: 'string',
  CurrentStatus: 'string',
  CurrentWarn: 'string',
  CurrLineA: 'float',
  CurrLineB: 'float',
  CurrLineC: 'float',
  DCA: 'float',
  DCDisconnect: 'float',
  DCDisconnect_string: 'string',
  DchgMWh: 'float',
  DCkW: 'float',
  DCV: 'float',
  DischCurrLimit: 'float',
  Hz: 'float',
  InvStatus: 'string',
  kVA: 'float',
  kVAR: 'float',
  kW: 'float',
  LocRemCtl: 'string',
  MaxAmbT: 'float',
  MaxCellT: 'float',
  MaxCellV: 'float',
  MaxSOC: 'float',
  MinAmbT: 'float',
  MinCellT: 'float',
  MinCellV: 'float',
  MinSOC: 'float',
  Mode: 'string',
  ModP: 'string',
  ModSt: 'string',
  PCS_MaxP: 'float',
  PCS_MinP: 'float',
  PCS_P: 'float',
  PCS_Q: 'float',
  PF: 'float',
  PhVphA: 'float',
  PhVphB: 'float',
  PhVphC: 'float',
  Pset: 'float',
  PV_PCS_LocalRemote_Control: 'string',
  PV_PCS_Night_SVG_Switch: 'string',
  Qset: 'float',
  RackCount: 'float',
  RackCurrent: 'float',
  RackMode: 'boolean_integer',
  racks_online: 'integer',
  RackSOC: 'float',
  RackSOH: 'float',
  RackVoltage: 'float',
  RTAC_P: 'float',
  RTAC_Q: 'float',
  RunMode: 'string',
  SOC: 'float',
  Status: 'boolean_integer',
  SysCurrent: 'float',
  SysHB: 'float',
  SysSOC: 'float',
  SysSOH: 'float',
  SysVoltage: 'float',
  UnitsOnline: 'float',
  Voltage: 'float',
  VoltPhAB: 'float',
  VoltPhAC: 'float',
  VoltPhAN: 'float',
  VoltPhBC: 'float',
  VoltPhBN: 'float',
  VoltPhCN: 'float',
  WatchdogResponse: 'integer',
};

export default DataTypePointNameMap;
