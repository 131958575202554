import React, { Dispatch, SetStateAction } from 'react';
import { CircularProgress, IconButton, Paper } from '@mui/material';
import EventTimeCell from '@src/components/Home/Alerts/Table/EventTimeCell';
import { ConfirmType, Note } from '@src/types/Notes';
import sortByDateDescending from '@src/utils/sortByDateDescending';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { UserState } from '@src/store/reducers/userData';
import { isAdmin } from '@src/services/auth';
import notesx from './NotesSx';
import './Notes.scoped.scss';

interface NotesListProps {
  notes: Note[];
  pending: boolean;
  username: UserState['username'];
  role: UserState['role'];
  handleOpenConfirm: (arg0: ConfirmType) => void;
  setSelectedNoteId: Dispatch<SetStateAction<string>>;
}

export default function NotesList({
  notes,
  pending,
  username,
  role,
  handleOpenConfirm,
  setSelectedNoteId,
}: NotesListProps) {
  const handleDeleteConfirm = (id: string) => {
    setSelectedNoteId(id);
    handleOpenConfirm(ConfirmType.DELETE);
  };

  if (pending) {
    return (
      <div className='notes_modal--loading'>
        <CircularProgress />
      </div>
    );
  }

  if (notes.length < 1) {
    return (
      <div className='notes_modal--loading'>
        <p>No notes to display</p>
      </div>
    );
  }

  return sortByDateDescending(notes, 'createdAt').map(
    ({ note, createdAt, id, user }) => (
      <Paper elevation={0} key={id} sx={notesx.notesWrapper}>
        <div className='notes_modal--note-time'>
          <EventTimeCell time={new Date(createdAt)} />
        </div>
        <div className='notes_modal--note-text'>{note}</div>
        {!process.env.VITE_READ_ONLY_MODE &&
          (username === user || isAdmin(role)) && (
            <IconButton
              size='small'
              sx={{
                color: '#cecece',
              }}
              onClick={() => handleDeleteConfirm(id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} size='sm' />
            </IconButton>
          )}
      </Paper>
    ),
  );
}
