import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import QueryKeys from '@src/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import {
  AlertActionType,
  AlertConfig,
  AlertConfigExtended,
} from '@src/types/Alerts';
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import useAlertMutations from '@src/hooks/api/mutations/useAlertMutations';
import AlertAddEditFormFields from './AlertAddEditFormFields';
import './AlertsConfig.scoped.scss';

interface AlertConfigDialogProps {
  alertConfig: AlertConfigExtended | null;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleCancel: (id?: GridRowId) => () => void;
  setBulkDelete: Dispatch<SetStateAction<boolean>>;
  bulkDelete: boolean;
  rowSelectionModel: GridRowSelectionModel;
  setActiveRow: Dispatch<
    SetStateAction<AlertConfig | AlertConfigExtended | null>
  >;
  isAdmin: boolean;
}

const schema = yup.object({
  alertName: yup.string().required('Alert name is required'),
  topic: yup.string().required('Topic is required'),
  thresholdMax: yup.string(),
  thresholdMin: yup.string(),
  messageAboveMax: yup.string(),
  messageBelowMin: yup.string(),
  severity: yup.number().required('Severity is required'),
  timeoutActivateMs: yup.string(),
  timeoutRemoveMs: yup.string(),
  isEquipmentFault: yup.boolean(),
});

// eslint-disable-next-line max-lines-per-function
export default function AlertsConfigDialog({
  alertConfig,
  setRowModesModel,
  setOpen,
  handleCancel,
  bulkDelete,
  rowSelectionModel,
  setActiveRow,
  isAdmin,
}: AlertConfigDialogProps) {
  const id = alertConfig?.id || '';

  let actionType;

  if (bulkDelete) {
    actionType = AlertActionType.BULK_DELETE;
  } else if (alertConfig?.remove) {
    actionType = AlertActionType.DELETE;
  } else if (alertConfig) {
    actionType = AlertActionType.EDIT;
  } else {
    actionType = AlertActionType.ADD;
  }

  const isDelete =
    actionType === AlertActionType.DELETE ||
    actionType === AlertActionType.BULK_DELETE;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      alertName: alertConfig?.alertName || '',
      topic: alertConfig?.topic || '',
      severity: alertConfig?.severity || 0,
      messageAboveMax: alertConfig?.messageAboveMax || '',
      messageBelowMin: alertConfig?.messageBelowMin || '',
      thresholdMax: alertConfig?.thresholdMax?.toString() ?? '',
      thresholdMin: alertConfig?.thresholdMin?.toString() ?? '',
      timeoutActivateMs: alertConfig?.timeoutActivateMs?.toString() ?? '',
      timeoutRemoveMs: alertConfig?.timeoutRemoveMs?.toString() ?? '',
      isEquipmentFault: alertConfig?.isEquipmentFault || false,
    },
  });

  const [messageAboveMax, messageBelowMin, thresholdMax, thresholdMin] =
    methods.watch([
      'messageAboveMax',
      'messageBelowMin',
      'thresholdMax',
      'thresholdMin',
    ]);

  const thresholdValidation = () => {
    const allEmpty =
      messageBelowMin === '' &&
      thresholdMin === '' &&
      messageAboveMax === '' &&
      thresholdMax === '';
    const failedMin =
      (thresholdMin !== '' && !messageBelowMin) ||
      (messageBelowMin !== '' && thresholdMin === '');
    const failedMax =
      (thresholdMax !== '' && !messageAboveMax) ||
      (messageAboveMax !== '' && thresholdMax === '');

    // returning true to disable
    return (
      // disable if all are empty
      allEmpty ||
      // disable if one of them or both are failing
      failedMin ||
      failedMax
    );
  };

  const queryClient = useQueryClient();

  const getTitle = () => {
    switch (actionType) {
      case AlertActionType.EDIT:
        return 'Edit Alert';
      case AlertActionType.DELETE:
        return 'Are you sure you want to delete this alert?';
      case AlertActionType.BULK_DELETE:
        return 'Are you sure you want to delete these alerts?';
      default:
        return 'Add Alert';
    }
  };

  const { createAlert, updateAlert, deleteAlert, bulkDeleteAlerts } =
    useAlertMutations(!bulkDelete ? id : undefined);

  const handleSuccessCallback = () => {
    if (id) {
      setRowModesModel((prevState) => ({
        ...prevState,
        [id]: { mode: GridRowModes.View },
      }));
    }

    queryClient.invalidateQueries({
      queryKey: [QueryKeys.alerts],
    });
    setActiveRow(null);
    setOpen(false);
  };

  const handleSave = (newData: AlertConfigExtended) => {
    if (actionType === AlertActionType.ADD) {
      thresholdValidation();
      return createAlert.mutate(newData, {
        onSuccess: handleSuccessCallback,
      });
    }
    return updateAlert.mutate(newData, {
      onSuccess: handleSuccessCallback,
    });
  };

  const handleDelete = () => {
    deleteAlert.mutate(
      {},
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.alerts],
          });
          setOpen(false);
        },
      },
    );
  };

  const handleBulkDelete = () => {
    const payload = {
      ids: rowSelectionModel,
    };

    bulkDeleteAlerts.mutate(payload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.alerts],
        });
        setOpen(false);
      },
    });
  };

  const onSubmit = (data: Record<string, unknown>) => {
    if (actionType === AlertActionType.DELETE) return handleDelete();

    const dataToSubmit = { ...data };
    Object.keys(dataToSubmit).forEach((key) => {
      if (dataToSubmit[key] === '') {
        dataToSubmit[key] = null;
      }
      if (
        [
          'thresholdMax',
          'thresholdMin',
          'timeoutActivateMs',
          'timeoutRemoveMs',
        ].includes(key) &&
        dataToSubmit[key] !== null
      ) {
        dataToSubmit[key] = Number(dataToSubmit[key]);
      }
    });

    return handleSave(dataToSubmit as unknown as AlertConfigExtended);
  };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          minWidth: '600px',
          p: 1,
        },
      }}
    >
      <FormProvider {...methods}>
        <form id='configForm' onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title'>{getTitle()}</DialogTitle>
          <DialogContent
            sx={{
              overflowY: 'auto',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {!isDelete && (
              <AlertAddEditFormFields topicFieldDisabled={!isAdmin} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel(alertConfig?.id)}>Cancel</Button>
            {bulkDelete ? (
              <Button onClick={handleBulkDelete} variant='contained'>
                Confirm
              </Button>
            ) : (
              <Button
                variant='contained'
                autoFocus
                type='submit'
                disabled={!isDelete && thresholdValidation()}
              >
                {isDelete ? 'Confirm' : 'Save'}
              </Button>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
