import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import './LineHighChart.scss';
import HighChart from '../HighChart';
import ChartLoader from '../ChartLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import { getLocale, usePrevious } from '@utils';
import storeConnector from '@store/storeConnector';

const DURATION_DICT = {
  '1M': 60 * 1000,
  '5M': 5 * 60 * 1000,
  '15M': 15 * 60 * 1000,
  '30M': 30 * 60 * 1000,
  '1H': 60 * 60 * 1000,
  '12H': 12 * 60 * 60 * 1000,
  '1D': 24 * 60 * 60 * 1000,
  '3D': 3 * 24 * 60 * 60 * 1000,
};

const LineHighChart = ({
  variables,
  durationSwitches,
  defaultShowVariables,
  timeMode,
  deviceId,
  siteMeta,
  dataLoadFunction,
  multiCategory,
  megaVars,
  unit,
  units,
  priorityVar,
  hasSecondAxis,
  minmax,
  margins,
  min,
  max,
  fixedYTicks,
  hideGrids,
  hideVarSwitches,
  children,
}) => {
  const varLabelsArr = Object.keys(variables);
  const varFieldsArr = Object.values(variables);
  const [activeDuration, setActiveDuration] = useState(
    durationSwitches.find((ds) => ds === '15M') || durationSwitches[0],
  );
  const [activeVars, setActiveVars] = useState(defaultShowVariables);
  const [chartOptions, setChartOptions] = useState(null);
  const isInit = useRef(false);
  const [chartData, setChartData] = useState([
    {
      date: moment(moment().format('YYYY-MM-DDTHH:mm:ss')).valueOf(),
      value: {},
    },
  ]);
  const prevActiveDuration = usePrevious(activeDuration);
  const activeDurationRef = useRef();
  const arrayVarValues = useRef({});
  const initTimeMode = useRef(timeMode);
  const prevTimeMode = useRef(timeMode);

  activeDurationRef.current = activeDuration;
  const category = !deviceId || deviceId === siteMeta.siteId ? 'site' : 'unit';

  // Below renders the chart after component loads
  // to allow flexbox _layout to load,
  // and the chart to fill container width and height
  const [isLayoutLoaded, setLayoutLoaded] = useState(false);
  useEffect(() => {
    setLayoutLoaded(true);
  }, []);

  useWsSubscribe({
    [category]: {
      ...(deviceId ? { sns: [deviceId] } : null),
      fields: varFieldsArr,
      cb: (data) => {
        Object.keys(data).forEach((f) => {
          if (typeof data[f] === 'object') {
            const arrayData = arrayVarValues.current;
            arrayData[f] = { ...arrayData[f], ...data[f] };
            arrayVarValues.current = arrayData;
          }
        });
        const currentTimeStamp = moment(
          moment().format('YYYY-MM-DDTHH:mm:ss'),
        ).valueOf();
        setChartData((prevValue) => {
          let prev = prevValue;
          if (
            prevValue.length === 1 &&
            !Object.keys(prevValue[0]?.value || {}).length
          ) {
            prev = [];
          }
          const activeDuration = activeDurationRef.current;
          const sameTSIdx = prev.findIndex(
            (dataPoint) => dataPoint.date === currentTimeStamp,
          );
          const newChartData = [...prev];
          if (prev.length) {
            const timeRangeStartIdx = prev.findIndex(
              (dataPoint) =>
                dataPoint.date >=
                prev[prev.length - 1].date - DURATION_DICT[activeDuration],
            );
            if (timeRangeStartIdx !== 0) {
              newChartData.splice(0, timeRangeStartIdx);
            }
          }
          const newValue = Object.keys(data).reduce((acc, cv) => {
            const newAcc = { ...acc };
            if (Object.keys(arrayVarValues.current).includes(cv)) {
              newAcc[cv] = Object.values(arrayVarValues.current[cv]).reduce(
                (a, c) => a + c,
                0,
              );
            } else {
              newAcc[cv] = data[cv];
            }
            return newAcc;
          }, {});
          if (sameTSIdx === -1) {
            newChartData.push({
              date: currentTimeStamp,
              value: { ...newValue },
            });
          } else {
            if (newChartData[sameTSIdx]) {
              newChartData[sameTSIdx] = {
                date: newChartData[sameTSIdx].date,
                value: {
                  ...newChartData[sameTSIdx].value,
                  ...newValue,
                },
              };
            }
          }
          return newChartData;
        });
      },
    },
  });
  const mergeArrsByDate = (arr) => {
    return arr.reduce((acc, cv) => {
      const newAcc = { ...acc };
      (cv || []).forEach((d) => {
        if (!newAcc[d.date]) {
          newAcc[d.date] = d;
        } else {
          newAcc[d.date] = { ...newAcc[d.date], ...d };
        }
      });
      return newAcc;
    }, {});
  };

  useEffect(() => {
    dataLoadFunction(activeDuration).then((res) => {
      const data = multiCategory
        ? Object.values(mergeArrsByDate(res) || {})
        : res;
      if (data?.length) {
        const newChartData = data
          .map((dataPoint) => {
            const newDpValue = Object.keys(dataPoint.value).reduce(
              (acc, cv) => {
                const splCv = cv.split('/');
                const field = splCv[splCv.length - 1];
                return { ...acc, [field]: dataPoint.value[cv] };
              },
              {},
            );
            return {
              value: newDpValue,
              date: getTimeStamp(dataPoint.date),
            };
          })
          .sort((a, b) => a.date - b.date);
        setChartData(newChartData);
      } else if (
        DURATION_DICT[prevActiveDuration] > DURATION_DICT[activeDuration]
      ) {
        setChartData((prev) => {
          if (prev.length) {
            const activeDuration = activeDurationRef.current;
            const timeRangeStartIdx = prev.findIndex(
              (dataPoint) =>
                dataPoint.date >=
                prev[prev.length - 1].date - DURATION_DICT[activeDuration],
            );
            return timeRangeStartIdx === 0
              ? prev
              : prev.slice(timeRangeStartIdx);
          } else {
            return [];
          }
        });
      }
    });
  }, [activeDuration]);

  const getYAxisLabelsFormatter = (i, units) => {
    return function () {
      return `<span class='highcharts-color-${getColorIndex(i)}'">${this.value}${
        units ? ` ${units}` : ''
      }</span>`;
    };
  };

  const getTimeStamp = (dataStr) => {
    return moment(moment(dataStr).format('YYYY-MM-DDTHH:mm:ss')).valueOf();
  };

  const getUnitsVarWithMega = () => {
    if (megaVars?.length) {
      return Object.keys(variables).reduce((acc, cv) => {
        const megaVar = megaVars.find((mv) => mv.prevLabel === cv);
        return {
          ...acc,
          [cv]: megaVar ? megaVar.newUnit : units[variables[cv]],
        };
      }, {});
    }
    return Object.keys(variables).reduce((acc, cv) => {
      return {
        ...acc,
        [cv]: units[variables[cv]],
      };
    }, {});
  };

  const getAxesOptions = (activeVars) => {
    const firstAxisVarIdx =
      priorityVar && activeVars.includes(priorityVar)
        ? varLabelsArr.indexOf(priorityVar)
        : varLabelsArr.indexOf(activeVars[0]);
    const secondAxisVarIdx = varLabelsArr.indexOf(
      activeVars.findLast((el) => el !== varLabelsArr[firstAxisVarIdx]),
    );
    const firstAxisUnitsStr = units
      ? megaVars?.length
        ? getUnitsVarWithMega()[varLabelsArr[firstAxisVarIdx]]
        : units[varFieldsArr[firstAxisVarIdx]]
      : '';
    let secondAxisUnitsStr;
    if (units) {
      const secondAxisVarLabelsArr = activeVars.filter(
        (el) => el !== varLabelsArr[firstAxisVarIdx],
      );
      const unitsArr = secondAxisVarLabelsArr.map((el) =>
        megaVars?.length ? getUnitsVarWithMega()[el] : units[variables[el]],
      );
      const unitsArrWithoutDuplicates = [...new Set(unitsArr)];
      secondAxisUnitsStr = `${unitsArrWithoutDuplicates.join(',')}`;
    }
    let minFirstAxis = null;
    let maxFirstAxis = null;
    let minSecondAxis = null;
    let maxSecondAxis = null;
    if (hasSecondAxis && minmax) {
      if (minmax[varLabelsArr[firstAxisVarIdx]]) {
        [minFirstAxis, maxFirstAxis] = minmax[varLabelsArr[firstAxisVarIdx]];
      }
      const secondAxisVarLabels = activeVars.filter(
        (el) => el !== varLabelsArr[firstAxisVarIdx],
      );
      if (secondAxisVarLabels.length) {
        minSecondAxis = Infinity;
        maxSecondAxis = -Infinity;
        secondAxisVarLabels.forEach((label) => {
          if (minmax[label]) {
            minSecondAxis = Math.min(minSecondAxis, minmax[label][0]);
            maxSecondAxis = Math.max(maxSecondAxis, minmax[label][1]);
          }
        });
        if (minSecondAxis === Infinity) {
          minSecondAxis = null;
        }
        if (maxSecondAxis === -Infinity) {
          maxSecondAxis = null;
        }
      }
    }
    return {
      firstAxisVarIdx,
      firstAxisUnitsStr,
      minFirstAxis,
      maxFirstAxis,
      secondAxisVarIdx,
      secondAxisUnitsStr,
      minSecondAxis,
      maxSecondAxis,
    };
  };

  const getColorIndex = (i) => {
    let index = i;
    // Adjust index based on the condition
    if (!siteMeta.ui.Battery_Enable && i >= 3) {
      index++;
    }
    return index;
  };

  useEffect(() => {
    const chartSiteData = [...chartData];
    if (chartSiteData?.length) {
      if (megaVars?.length && chartSiteData) {
        chartSiteData.forEach((el, i) => {
          megaVars
            .map((el) => variables[el.prevLabel])
            .forEach((v) => {
              if (el.value && el.value[v]) {
                chartSiteData[i].value[v] = (
                  +chartSiteData[i].value[v] / 1000
                ).toFixed(5);
              }
            });
        });
      }
      const dataArrays = varFieldsArr.map((varField) => {
        return chartSiteData.reduce((dataArr, dataPoint) => {
          if (
            dataPoint.value[varField] !== null &&
            dataPoint.value[varField] !== undefined
          ) {
            dataArr.push([dataPoint.date, +dataPoint.value[varField]]);
          }
          return dataArr;
        }, []);
      });
      if (!isInit.current) {
        isInit.current = true;
        const {
          firstAxisVarIdx,
          firstAxisUnitsStr,
          minFirstAxis,
          maxFirstAxis,
          secondAxisVarIdx,
          secondAxisUnitsStr,
          minSecondAxis,
          maxSecondAxis,
        } = getAxesOptions(activeVars);
        const seriesArr = dataArrays.map((data, i) => {
          const valueSuffix = units
            ? megaVars?.length
              ? ` ${getUnitsVarWithMega()[varLabelsArr[i]]}`
              : ` ${units[varFieldsArr[i]]}`
            : undefined;
          return {
            visible: defaultShowVariables.includes(varLabelsArr[i]),
            colorIndex: getColorIndex(i),
            name: varLabelsArr[i],
            data,
            yAxis: i !== firstAxisVarIdx && hasSecondAxis ? 1 : 0,
            tooltip: {
              valueSuffix,
            },
          };
        });
        setChartOptions({
          chart: {
            styledMode: true,
            marginTop: margins?.top,
            marginRight: margins?.right,
            marginBottom: margins?.bottom,
            marginLeft: margins?.left,
          },
          title: {
            text: ' ',
          },
          yAxis: [
            {
              title: {
                text: ' ',
              },
              labels: {
                useHTML: true,
                formatter: hasSecondAxis
                  ? getYAxisLabelsFormatter(firstAxisVarIdx, firstAxisUnitsStr)
                  : undefined,
              },
              min: min !== undefined ? min : minFirstAxis,
              max: max !== undefined ? max : maxFirstAxis,
              tickAmount: fixedYTicks,
            },
            ...(hasSecondAxis
              ? [
                  {
                    title: {
                      text: '',
                    },
                    labels: {
                      useHTML: true,
                      formatter: getYAxisLabelsFormatter(
                        secondAxisVarIdx,
                        secondAxisUnitsStr,
                      ),
                    },
                    min: minSecondAxis,
                    max: maxSecondAxis,
                    tickAmount: fixedYTicks,
                    opposite: true,
                  },
                ]
              : []),
          ],
          xAxis: {
            type: 'datetime',
            units: [
              ['second', [1, 2, 5, 10, 15, 30]],
              ['minute', [1, 2, 5, 30, 60, 180]],
            ],
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
          },
          legend: {
            enabled: false,
          },
          time: {
            useUTC: true,
            timezoneOffset:
              getLocale({
                timeMode: timeMode,
                lat: siteMeta.GPSLat,
                long: siteMeta.GPSLong,
                returnOffset: true,
              }) -
              getLocale({
                timeMode: 'UTC',
                initTZMode: 'User',
                lat: siteMeta.GPSLat,
                long: siteMeta.GPSLong,
                returnOffset: true,
              }),
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          tooltip: {
            useHTML: true,
            formatter: function () {
              let content =
                Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + '<br/>';
              const actualUnit = units
                ? units[variables[this.series.name]]
                : unit
                  ? unit
                  : '';
              content +=
                this.series.name +
                ': ' +
                Highcharts.numberFormat(this.point.y, 2) +
                actualUnit;

              return content;
            },
          },
          series: seriesArr,
        });
      } else {
        setChartOptions({ series: dataArrays.map((data) => ({ data })) });
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (isInit.current && prevTimeMode.current !== timeMode) {
      setChartOptions((prev) => ({
        ...prev,
        time: {
          useUTC: true,
          timezoneOffset:
            getLocale({
              initTZMode: initTimeMode.current,
              timeMode: timeMode,
              lat: siteMeta.GPSLat,
              long: siteMeta.GPSLong,
              returnOffset: true,
            }) -
            getLocale({
              timeMode: 'UTC',
              initTZMode: 'User',
              lat: siteMeta.GPSLat,
              long: siteMeta.GPSLong,
              returnOffset: true,
            }),
        },
      }));
      prevTimeMode.current = timeMode;
    }
  }, [timeMode]);

  const setActiveVar = (varName) => {
    const i = activeVars.indexOf(varName);
    const newActiveVars = [...activeVars];
    if (i === -1) {
      newActiveVars.push(varName);
    } else {
      newActiveVars.splice(i, 1);
    }
    setActiveVars(newActiveVars);
    const series = Array.from({ length: varLabelsArr.length }, () => ({
      visible: false,
      ...(hasSecondAxis ? { yAxis: 1 } : []),
    }));
    const {
      firstAxisVarIdx,
      firstAxisUnitsStr,
      minFirstAxis,
      maxFirstAxis,
      secondAxisVarIdx,
      secondAxisUnitsStr,
      minSecondAxis,
      maxSecondAxis,
    } = getAxesOptions(newActiveVars);

    newActiveVars.forEach((activeVar) => {
      const index = varLabelsArr.indexOf(activeVar);
      series[index].visible = true;
      if (hasSecondAxis) {
        series[firstAxisVarIdx].yAxis = 0;
      }
    });
    const yAxis = [
      {
        labels: {
          formatter: getYAxisLabelsFormatter(
            firstAxisVarIdx,
            firstAxisUnitsStr,
          ),
        },
        min: minFirstAxis,
        max: maxFirstAxis,
      },
      ...(hasSecondAxis
        ? [
            {
              labels: {
                formatter: getYAxisLabelsFormatter(
                  secondAxisVarIdx,
                  secondAxisUnitsStr,
                ),
              },
              min: minSecondAxis,
              max: maxSecondAxis,
            },
          ]
        : []),
    ];

    setChartOptions({
      series,
      yAxis,
    });
  };

  const includesMega = (v) => {
    if (megaVars?.length) {
      const megaVar = megaVars.find((el) => el.prevLabel === v);
      if (megaVar) {
        return megaVar.newLabel;
      }
      return v;
    }
    return v;
  };

  return (
    isLayoutLoaded &&
    chartOptions !== null && (
      <>
        <div
          className={`line-chart-container${hideGrids ? ' hide-grids' : ''}`}
        >
          {children}
          <div className='dur-switches'>
            {durationSwitches.length > 1 &&
              durationSwitches.map((ds, i) => (
                <div
                  key={i}
                  className={ds === activeDuration ? 'active' : undefined}
                  onClick={() => setActiveDuration(ds)}
                >
                  {ds}
                </div>
              ))}
          </div>
          {hideVarSwitches !== true && (
            <div className='vars-switches'>
              {Object.keys(variables).map((v, i) => {
                return (
                  <div
                    key={i}
                    className={
                      activeVars.includes(v)
                        ? `${v} active color-chart-${getColorIndex(i)}`
                        : undefined
                    }
                    onClick={() => {
                      setActiveVar(v);
                    }}
                  >
                    {includesMega(v)}
                  </div>
                );
              })}
            </div>
          )}
          <HighChart
            options={chartOptions}
            containerProps={{ style: { height: '100%' } }}
          />
        </div>
        {chartData.length <= 1 &&
          !Object.keys(chartData[0]?.value || {}).length && (
            <ChartLoader cover='container' />
          )}
      </>
    )
  );
};

export default storeConnector(LineHighChart, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
