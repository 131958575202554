import useSubscription from '@hooks/useSubscription';
import { isActionRole, isAdmin } from '@src/services/auth';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import { UserState } from '@store/reducers/userData';
import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import useDataPointsSearch from '@src/hooks/api/queries/useDataPointsSearch';
import { generateDataPoint } from '@src/utils/generateTopic';
import { generateMqttClient } from '@src/pages/_loader/initMqttClient.loader';
import { MqttClient } from 'mqtt';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import ControlSection from './ControlSection';
import PowerSection from './PowerSection';
import { CommandsModalContextProvider } from '../CommandsModalContext';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  deviceId: string;
  deviceName: string;
  open: boolean;
  onClose: () => void;
  siteMeta: SiteMeta;
  role: UserState['role'];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, max-lines-per-function
function TSCCommandsModal({
  deviceId,
  deviceName,
  open,
  onClose,
  siteMeta,
  role,
}: Props) {
  const CHARGE_POINT_NAME = siteMeta.TeslaChargePowerPointName;
  const DISCHARGE_POINT_NAME = siteMeta.TeslaDischargePowerPointName;
  const QSET = 'Qset';
  const PSET = 'Pset';

  const [mqttClient, setMqttClient] = React.useState<MqttClient | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!open) return () => {};
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };

    startClient();
    return () => mqttClient?.end();
    // eslint-disable-next-line
  }, [open]);

  const onDialogClose = () => {
    onClose();
    mqttClient?.end();
  };

  const { data: dataPoints } = useDataPointsSearch('cmd-modal-power', [
    { category: 'site', pointName: PSET },
    { category: 'site', pointName: QSET },
  ]);

  const pointsToSubscribe = useMemo(() => {
    const minMaxDataPoints: DataPoint[] = [
      CHARGE_POINT_NAME,
      DISCHARGE_POINT_NAME,
    ].map((pointName: string) =>
      generateDataPoint(
        true,
        {
          category: 'tsc',
          controllerId: siteMeta.siteId,
          sourceDeviceId: deviceId,
          pointName,
          pointAlias: pointName,
        },
        siteMeta.siteId,
        'ems',
        null,
      ),
    );
    return [...(dataPoints || []), ...minMaxDataPoints];
  }, [
    dataPoints,
    deviceId,
    siteMeta.siteId,
    CHARGE_POINT_NAME,
    DISCHARGE_POINT_NAME,
  ]);

  const dataPointsWithValue: DataPointWithValue[] =
    useSubscription(pointsToSubscribe);

  const displayEnableDisableCommandsSection: boolean = useMemo((): boolean => {
    //  if siteMeta role check is false or doesn't exist then
    //  operator, engineer, admin, superadmin
    //  can view the enable / disable command section
    //  is role check is on then only admin / superadmin
    //  can view. The "viewer" role can never see, regardless of
    //  the siteMeta flag
    if (
      (siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands &&
        isAdmin(role)) ||
      (!siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands &&
        isActionRole(role))
    ) {
      return true;
    }
    return false;
  }, [role, siteMeta.ui.TSC_Only_Allow_Admin_Enable_Disable_Commands]);

  const checkForPowerDatapointValue = (pointName: string): boolean =>
    dataPointsWithValue?.find(
      (dataPoint: DataPointWithValue): boolean =>
        dataPoint.pointName === pointName,
    )?.value !== undefined;
  const getDatapointValue = (pointName: string) =>
    dataPointsWithValue?.find(
      (dataPoint: DataPointWithValue): boolean =>
        dataPoint.pointName === pointName,
    )?.value;
  const isDataPointsSet = (): boolean =>
    checkForPowerDatapointValue(PSET) && checkForPowerDatapointValue(QSET);

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.TSC}
      deviceId={siteMeta.siteId}
    >
      <Dialog
        open={isDataPointsSet()}
        onClose={onDialogClose}
        maxWidth='sm'
        fullWidth
      >
        <StyledDialogTitle>
          {deviceName} Commands
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          {displayEnableDisableCommandsSection && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <ControlSection />
            </Box>
          )}
          <PowerSection
            currentPset={String(getDatapointValue(PSET))}
            currentQset={String(getDatapointValue(QSET))}
            minThreshold={Number(getDatapointValue(CHARGE_POINT_NAME))}
            maxThreshold={Number(getDatapointValue(DISCHARGE_POINT_NAME))}
            deviceId={deviceId}
          />
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(TSCCommandsModal, {
  config: ['siteMeta'],
  user: ['role'],
});
