import { UserState } from '@store/reducers/userData';
import React, { ReactElement, ReactNode } from 'react';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { DataPointWithValue } from '@src/types/DataPoint';
import { BooleanInteger } from '@types';
import Indicator from '@components/_shared/Indicator';
import storeConnector from '@store/storeConnector';
import { isSuperAdmin } from '@src/services/auth';
import { formatFloat, isNumberTypeGuard } from '@utils/index_ts';

const DEFAULT_PRECISION = 2;

function parseValue(
  value: unknown,
  dataType: string,
  scalingFactor: number | null,
  decimalPrecision: number | null | undefined,
  fullTopic: string,
  invertDisplay?: boolean,
): string | ReactNode {
  if (value === undefined) {
    return (
      <Box role='spinner'>
        <CircularProgress size={15} />
      </Box>
    );
  }

  if (value === null) {
    return 'ERR';
  }

  if (dataType === 'integer') {
    if (!isNumberTypeGuard(value)) {
      console.error(
        `ValueLoader: value ${value} is not a number for topic ${fullTopic}`,
      );
      return 'ERR';
    }
    return Math.floor(value).toString();
  }

  if (dataType === 'float') {
    if (!isNumberTypeGuard(value)) {
      console.error(
        `ValueLoader: value ${value} is not a number for topic ${fullTopic}`,
      );
      return 'ERR';
    }
    return formatFloat(
      value,
      scalingFactor,
      decimalPrecision,
      DEFAULT_PRECISION,
    );
  }

  if (dataType === 'boolean_integer') {
    if (!isNumberTypeGuard(value) || ![0, 1].includes(value)) {
      console.error(
        `ValueLoader: value ${value} is not a boolean integer for topic ${fullTopic}`,
      );
      return 'ERR';
    }

    return (
      <Indicator
        value={value as BooleanInteger}
        invertDisplay={invertDisplay}
      />
    );
  }

  return value.toString();
}

function ValueLoaderContent(p: {
  dataPoint: DataPointWithValue;
}): ReactElement {
  const {
    value,
    dataType,
    fullTopic,
    scalingFactor,
    decimalPrecision,
    invertDisplay,
  } = p.dataPoint;

  const parsedValue = parseValue(
    value,
    dataType,
    scalingFactor,
    decimalPrecision,
    fullTopic,
    invertDisplay,
  );

  return <Box>{parsedValue}</Box>;
}

interface ValueLoaderProps {
  dataPoint: DataPointWithValue;
  role: UserState['role'];
}

function ValueLoader(p: ValueLoaderProps): ReactElement {
  const title = isSuperAdmin(p.role) ? p.dataPoint.fullTopic : '';
  return (
    <Tooltip placement='right' role='tooltip' title={title}>
      <Box>
        <ValueLoaderContent dataPoint={p.dataPoint} />
      </Box>
    </Tooltip>
  );
}

export default storeConnector(ValueLoader, {
  user: ['role'],
});
