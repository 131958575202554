import { isActionRole, isAdmin } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import storeConnector from '@store/storeConnector';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AlertConfig, AlertConfigExtended } from '@src/types/Alerts';
import {
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridRowId,
  useGridApiRef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import AlertsConfigDialog from '@src/components/Home/Alerts/AlertsConfigDialog';
import '@components/Home/Alerts/AlertsConfig.scoped.scss';
import AlertsConfigToolbar from '@src/components/Home/Alerts/Table/AlertsConfigToolbar';
import prepConfigColumns from '@src/components/Home/Alerts/utils/prepConfigColumns';
import { initialDataGridState } from '@src/components/Home/Alerts/config';
import useGetAlertConfigsQuery from '@src/hooks/api/queries/alerts/useGetAlertConfigsQuery';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setBulkDelete: Dispatch<SetStateAction<boolean>>;
    rowSelectionModel: GridRowSelectionModel;
  }
}

// eslint-disable-next-line max-lines-per-function
function AlertConfigs({ role }: { role: UserRole }) {
  const [rows, setRows] = useState<AlertConfigExtended[]>([]);
  const [activeRow, setActiveRow] = useState<
    AlertConfig | AlertConfigExtended | null
  >(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [bulkDelete, setBulkDelete] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const apiRef = useGridApiRef();

  const { data, isFetching, isPending, error, isSuccess, isRefetching } =
    useGetAlertConfigsQuery();

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    if (apiRef.current !== undefined) {
      const active = apiRef.current.getRow(id);
      setActiveRow(active);
    }
    setOpen(true);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (apiRef.current !== undefined) {
      const active = apiRef.current.getRow(id);
      setActiveRow({ ...active, remove: true });
    }
    setOpen(true);
  };

  const handleCancelClick = (id?: GridRowId) => () => {
    if (id !== undefined) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View },
      });
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew === true) {
        setRows(rows.filter((row) => row.id !== id));
      }
    }
    setActiveRow(null);
    setBulkDelete(false);
    setOpen(false);
  };

  useEffect(() => {
    if (error) {
      setErrorMsg('Issue retrieving alert configs');
    }
    if (isSuccess && data !== null) {
      setRows(data.map((alert) => ({ ...alert, isNew: false, id: alert.id })));
    }
  }, [isSuccess, data, error]);

  return (
    <div className='home-row frow' id='alerts-config-wrapper'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <DataGridPro
            rows={rows}
            columns={prepConfigColumns({
              role,
              rowSelectionModel,
              handleEditClick,
              handleDeleteClick,
            })}
            editMode='row'
            getRowId={({ id }) => id}
            apiRef={apiRef}
            pageSizeOptions={[25, 50, 100]}
            initialState={{
              ...initialDataGridState,
              columns: {
                columnVisibilityModel: {
                  ...initialDataGridState?.columns?.columnVisibilityModel,
                  topic: true,
                },
              },
              pagination: {
                paginationModel: { pageSize: 50, page: 0 },
              },
              sorting: {
                sortModel: [{ field: 'alertName', sort: 'asc' }],
              },
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: ['actions'],
              },
            }}
            onRowSelectionModelChange={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            rowModesModel={rowModesModel}
            checkboxSelection={isAdmin(role)}
            slots={
              isActionRole(role) &&
              !process.env.VITE_READ_ONLY_MODE &&
              isAdmin(role)
                ? {
                    toolbar: AlertsConfigToolbar,
                  }
                : {}
            }
            slotProps={{
              toolbar: {
                setOpen,
                setBulkDelete,
                rowSelectionModel,
              },
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            loading={!isRefetching && (isFetching || isPending)}
            localeText={{
              noRowsLabel: errorMsg ?? 'No alert configs.',
            }}
          />

          {open && (
            <AlertsConfigDialog
              alertConfig={activeRow}
              setRowModesModel={setRowModesModel}
              setOpen={setOpen}
              handleCancel={handleCancelClick}
              bulkDelete={bulkDelete}
              setBulkDelete={setBulkDelete}
              rowSelectionModel={rowSelectionModel}
              setActiveRow={setActiveRow}
              isAdmin={isAdmin(role)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default storeConnector(AlertConfigs, {
  user: ['role'],
});
