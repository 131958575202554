import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import { EditUserFormValues } from '@src/hooks/api/mutations/useUserUpdate';
import useGroups from '@src/hooks/api/queries/useGroups';
import storeConnector from '@store/storeConnector';
import { useFormContext } from 'react-hook-form';
import useUserGroups from '@src/hooks/api/queries/useUserGroups';
import { UserState } from '@src/store/reducers/userData';
import { isSuperAdmin } from '@src/services/auth';

interface GroupFieldProps {
  userId?: string;
  role: UserState['role'];
}

function GroupField({ userId, role }: GroupFieldProps) {
  const { data: groups, isLoading } = useGroups();
  const { data: userGroups = [], isFetching: isFetchingUserGroups } =
    useUserGroups(userId);

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EditUserFormValues | AddUserFormValues>();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setValue('groups', value as string[]);
  };

  useEffect(() => {
    if (!isFetchingUserGroups) {
      setValue(
        'groups',
        userGroups.map(({ name }) => name),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUserGroups]);

  return (
    <FormControl fullWidth error={!!errors.groups}>
      <InputLabel size='small'>Groups</InputLabel>
      <Select
        size='small'
        label='Group'
        disabled={isLoading || isFetchingUserGroups}
        value={watch('groups')}
        multiple
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {groups?.map((group) => {
          if (!isSuperAdmin(role) && group.name === 'SuperAdmin') {
            return null;
          }
          return (
            <MenuItem key={group.id} value={group.name}>
              <Checkbox checked={watch('groups').indexOf(group.name) > -1} />
              <ListItemText primary={group.name} />
            </MenuItem>
          );
        })}
      </Select>
      {!!errors.groups && (
        <FormHelperText error>{errors.groups.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default storeConnector(GroupField, {
  user: ['role'],
});
