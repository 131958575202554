import React, { ReactElement } from 'react';
import Circle from '@src/sld/components/Circle/Circle';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';
import { Resistor } from '@src/sld/components/Resistor/Resistor';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';

type TopSwitchResistorProps = {
  translateXCoordinate: number;
  translateYCoordinate: number;
};

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

// eslint-disable-next-line max-lines-per-function
function TopSwitchResistorSection(props: TopSwitchResistorProps): ReactElement {
  const { translateXCoordinate, translateYCoordinate } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;
  return (
    <g transform={translate}>
      <Circle
        label='66kV POI'
        translateXCoordinate={708}
        translateYCoordinate={10}
        diameter={3}
        stroke='white'
        strokeWidth={0.5}
        fillColor='black'
        overrideTextXCoordinate={-50}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={17}
        length={8}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <Switch
        label='89T1'
        isClosed={false}
        translateXCoordinate={708}
        translateYCoordinate={25}
        stroke='green'
        overrideLabelXCoordinate={28}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={35}
        length={14}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <rect
        x={698}
        y='50'
        width='20'
        height='20'
        fill='grey'
        strokeWidth={0.5}
      />
      <text x={737} y={65} {...rectTextProps}>
        52T1
      </text>

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={70}
        length={22}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <g transform='rotate(90 700 783)'>
        <Resistor label='T1' />
      </g>

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={105}
        length={20}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <Switch
        label='89T2'
        isClosed={false}
        translateXCoordinate={708}
        translateYCoordinate={125}
        stroke='green'
        overrideLabelXCoordinate={30}
      />

      <VerticalLine
        translateXCoordinate={708}
        translateYCoordinate={135}
        length={37}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <VerticalLine
        translateXCoordinate={235}
        translateYCoordinate={590}
        length={10}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <HorizontalLine
        translateXCoordinate={708}
        translateYCoordinate={155}
        color='white'
        width={0.5}
        length={20}
      />
      <Circle
        label='SCE - ION 8650'
        innerText='M'
        translateXCoordinate={739}
        translateYCoordinate={145}
        diameter={10}
        stroke='white'
        strokeWidth={0.5}
      />
    </g>
  );
}

export default TopSwitchResistorSection;
