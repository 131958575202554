import { BrowserHistory, createBrowserHistory } from 'history';
import { Action } from 'redux';
import { TimeMode } from '@types';
import { NotesState } from '@src/types/Notes';

export interface ServiceState {
  history: BrowserHistory;
  weatherForecast: unknown;
  isShownCommandsPopup: boolean;
  noteProps: NotesState;
  popupProps: unknown;
  isControlsLocked: boolean;
  tmpNotifications: unknown[];
  updateFinished: boolean;
  currentInverterIdx: unknown;
  showMap: boolean;
  darkTheme: boolean;
  timeMode: TimeMode;
  currentUnitDeviceId: string | null;
  currentUnitName: string | null;
  audioNotificationEnabled: boolean;
  bannerNotificationEnabled: boolean;
  ampm?: boolean;
}

const initState: ServiceState = {
  history: createBrowserHistory(),
  weatherForecast: null,
  isShownCommandsPopup: false,
  noteProps: {
    deviceId: '',
    isNotesOpen: false,
  },
  popupProps: null,
  isControlsLocked: false,
  tmpNotifications: [],
  updateFinished: false,
  currentInverterIdx: undefined,
  showMap: true,
  darkTheme: true,
  timeMode: 'UTC',
  currentUnitDeviceId: null,
  currentUnitName: null,
  audioNotificationEnabled: false,
  bannerNotificationEnabled: false,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ServiceState = initState,
  action: Action<string> & ServiceState,
) => {
  const handlers: Record<string, () => ServiceState> = {
    UPDATE_SERVICE_MODULE: () => {
      const { type: _type, ...cleanAction } = action;
      return { ...state, ...cleanAction };
    },
  };
  if (action.type && action.type in handlers) {
    return handlers[action.type]();
  }
  return state;
};
