import React, { ReactElement } from 'react';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function BreakerStack2(): ReactElement {
  return (
    <g transform='translate(990 -480)'>
      <VerticalLine
        translateXCoordinate={-112.5}
        translateYCoordinate={703}
        length={20}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
      <Switch
        label='89F1-1'
        isClosed={false}
        translateXCoordinate={-112}
        translateYCoordinate={692}
        stroke='green'
      />

      <rect
        x={-123}
        y='723'
        width='20'
        height='20'
        fill='grey'
        strokeWidth={0.5}
      />
      <text x={-90} y={735} {...rectTextProps}>
        52F1
      </text>

      <VerticalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={744}
        length={30}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
    </g>
  );
}

export default BreakerStack2;
