import {
  legacy_createStore as createStore,
  applyMiddleware,
  Action,
} from 'redux';
import thunk from 'redux-thunk';
import { MqttClient } from 'mqtt';
import rootReducer, { AggregatedState } from '@store/reducers';
import { AlertDispatchProps } from '@store/reducers/alerts';
import { TimeMode } from '@types';

const store = createStore(rootReducer, applyMiddleware(thunk));
export { store };

export function dispatcher(
  type: string,
  payload?:
    | Partial<AggregatedState>
    | Partial<AlertDispatchProps>
    | { sn?: string }
    | { clientMQTT: MqttClient }
    | { timeMode: TimeMode },
): Action<string> & Record<string, unknown> {
  return {
    type,
    ...payload,
  };
}
